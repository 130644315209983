.nav {
	height: 100%;
	background-color: var(--oc-palette-opteraBackground-main);
	position: relative;
	transition: width 0.2s;
	padding: 16px;
	border-right: 1px solid var(--oc-palette-secondary-200);
	z-index: 4;
}

.toggle {
	opacity: 0;
	position: absolute;
	right: -15px;
	top: 16px;
	height: 34px;
	width: 34px;
	border: 1px solid var(--oc-palette-secondary-200);
	color: var(--oc-palette-text-secondary);
	border-radius: 8px;
	background-color: var(--oc-palette-secondary-200);
	padding: 6px 4px 0px 4px;
	font-size: 9px;
	font-weight: 700;
	line-height: 20px;
}

.nav:hover .toggle,
.nav:focus-within .toggle,
.toggle:focus {
	opacity: 1;
	cursor: pointer;
}

.toggle:hover,
.toggle:focus {
	color: var(--oc-palette-opteraBackground-main);
	background-color: var(--oc-palette-primary-600);
}

.nav ul {
	overflow: hidden;
	list-style-type: none;
	transition: width 0.4s;
	padding: 0;
	list-style-type: none;
}

.nav.wide ul {
	width: 100%;
}

.nav li {
	display: flex;
	flex-direction: row;
	color: var(--oc-palette-text-secondary);
	border-radius: 8px;
	margin-bottom: 8px;
	height: 36px;
}

.nav li:hover {
	color: var(--oc-palette-text-primary);
	background-color: var(--oc-palette-action-hover);
}

.footer {
	display: block;
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 100%;
	padding: 0px 16px 16px 16px;
}

.selected {
	color: var(--oc-palette-text-primary);
	background-color: var(--oc-palette-action-selected);
}

.currentOrg {
	display: flex;
	flex-direction: row;
	margin-bottom: 32px;
	margin-left: 8px;
}

.currentOrgName {
	margin: 10px 0px 10px 10px;
	font-weight: 500;
}

.companyAvatar {
	background-color: var(--oc-palette-sky-blue-100);
	color: var(--oc-palette-text-primary);
	font-size: 18px;
	font-weight: 600;
}

.companyAvatar:hover {
	border: 2px solid var(--oc-palette-secondary-200);
}
/* subtitle 1 */
.navLink {
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
}

.navLink:focus-visible {
	outline: 2px solid #005fcc;
	outline-offset: -2.5px;
	padding-right: 6px;
	border-radius: 4px; /* Optional */
}
.marginTransition > div {
	padding-top: 0;
	transition: all 0.2s ease-in;
}

.marginTransition .avatarMarginClosed {
	padding-top: 50px;
}
